/* CSS (OTPVerification.module.css) */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100001;
}

.loginBox {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 1001;
    position: relative;
    text-align: center;
}

.closeButton {
    position: absolute; /* Add this */
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.closeButton :hover{
    color: red;
}


.iconHeader {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.iconHeader img {
    width: 50px;
    height: 50px;
}

h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

p {
    margin: 10px 0;
    color: #666;
}

.inputGroup {
    display: flex;
    align-items: center;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.formInput {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: none;
    outline: none;
}

.submitButton {
    background: #ffca08;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.submitButton:hover {
    background: #ffca08;
}

.backButton {
    width: 100%;
    padding: 10px;
    background: #ffca08;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backButton:hover {
    background: #5a54e1;
}

.backButton .icon {
    margin-right: 8px; /* Space between icon and text */
}

.errorMessage {
    color: #ff0000;
    font-size: 14px;
    margin: 10px 0;
}


.otpInput {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 80%;
}


@media (max-width: 768px) {
    .loginBox {
        width: 95%;
    }
}
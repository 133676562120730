.blog1container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.blog1title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.blog1paragraph {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

.blog1heading {
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 15px;
}

.blog1imagePlaceholder {
  width: 100%;
  height: 300px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #888;
}

.blog1orderButton {
  background-color: #28a745;
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  display: block;
  margin: 40px auto;
  transition: background-color 0.3s;
}

.blog1orderButton:hover {
  background-color: #218838;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .blog1title {
    font-size: 2rem;
  }

  .blog1paragraph {
    font-size: 1rem;
  }

  .blog1heading {
    font-size: 1.5rem;
  }

  .blog1imagePlaceholder {
    height: 200px;
  }

  .blog1orderButton {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .blog1title {
    font-size: 1.8rem;
  }

  .blog1paragraph {
    font-size: 0.9rem;
  }

  .blog1heading {
    font-size: 1.3rem;
  }

  .blog1imagePlaceholder {
    height: 150px;
  }

  .blog1orderButton {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
}

.blog3Container {
    padding: 20px;
    background-color: #fff;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }
  
  .blog3Title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  .blog3Intro {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
    color: #555;
  }
  
  .blog3Subtitle {
    font-size: 2rem;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #222;
  }
  
  .blog3Text {
    font-size: 1.1rem;
    margin-bottom: 15px;
    line-height: 1.6;
    color: #666;
  }
  
  .imagePlaceholder {
    height: 200px;
    background-color: #e0e0e0;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    font-size: 1.2rem;
  }
  
  ul {
    margin-left: 20px;
    color: #555;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  .link {
    color: #007bff;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .footer {
    margin-top: 40px;
    text-align: center;
    font-size: 1rem;
    color: #666;
  }
  
.blog5container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog5title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .blog5heading {
    font-size: 1.8rem;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .blog5paragraph {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .imageSpace {
    width: 100%;
    height: 300px;
    background-color: #f0f0f0;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .orderLink {
    color: #ff6347;
    text-decoration: underline;
  }
  
  .menuLink {
    color: #4CAF50;
    text-decoration: underline;
  }
  
.blog2Container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.blog2Title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
}

.blog2Subtitle {
    font-size: 1.5rem;
    margin-top: 20px;
}

.imagePlaceholder {
    height: 200px; /* Placeholder height for images */
    background-color: #f0f0f0; /* Light gray for placeholder */
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
}

.link {
    color: #007bff;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.footer {
    margin-top: 40px;
    text-align: center;
    font-size: 0.9rem;
    color: #666;
}

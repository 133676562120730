/* Header styles */
.header {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
}

.logo img {
  height: 50px;
}

/* Desktop menu styles */
.navDesktop {
  display: flex;
  align-items: center;
}

.navDesktop ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navDesktop ul li {
  margin: 0 10px;
  display: flex; /* Align items horizontally */
  align-items: center; /* Vertically center items */
}

.navDesktop ul li a {
  text-decoration: none;
  color: #ffca08;
  font-weight: bold;
}

.profileMenu {
  position: relative;
}

/* Mobile menu styles */
.navMobile {
  display: none;
}

.menuIcon {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
}

.menuIcon div {
  width: 25px;
  height: 3px;
  background-color: #ffca08;
}

/* Animation keyframes */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile menu styles */
.mobileMenu {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 1000;
  text-align: center;
  animation: slideDown 0.3s ease-out; /* Apply animation */
}

.mobileMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobileMenu ul li {
  padding: 10px 0;
}

.mobileMenu ul li a {
  text-decoration: none;
  color: #ffca08;
  font-weight: bold;
}

/* Toggle switch container */
.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the container in mobile view */
  margin: 10px 0; /* Add margin for spacing in mobile view */
}

/* The switch - hidden checkbox */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider - the toggle */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

/* Slider before (circle inside the switch) */
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: white;
  transition: .4s;
  top: 4px;
  left: 4px;
}

/* When the checkbox is checked, slide the slider */
input:checked + .slider {
  background-color: #ffca08;
}

/* Move the slider circle to the right */
input:checked + .slider:before {
  transform: translateX(14px);
}

/* Status text styles */
.statusText {
  font-weight: bold;
}

.statusOnline {
  color: rgb(73, 255, 73);
}

.statusOffline {
  color: red;
}

@media (max-width: 768px) {
  .navDesktop {
    display: none;
  }

  .navMobile {
    display: block;
  }
}
